"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = isBlank;
function isBlank(object) {
  if (object === true || object === false || object === 0) {
    return false;
  }
  return object === null || object === undefined || object === '' || Array.isArray(object) && object.length === 0;
}
;